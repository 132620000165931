<template>
    <div style="" class=" spaceField"> </div>
</template>

<script>
import http from '../../mixins/api'
import InputText from 'primevue/inputtext';
import { uuid } from "vue-uuid";
import store from '@/store'



export default {
    name: 'spaceField',
    props: ['item', 'Id', 'readOnly', 'preview', 'required', 'disabled'],
    emits: [],
    components: {
    },
    created() {
    },
    data() {
        return {
        }
    },
    methods: {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>


