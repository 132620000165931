<template>
    <div v-if="editor" class="width-100" :class="[editorContainerClass]" :style="{'min-height': !useMinHeight ? 'unset' : (disabled || disableEnterKey) ? '20px' : '185px', 'max-height': disabled || disableEnterKey ? '200px' : 'unset'}">
        <bubble-menu
                class="bubble-menu"
                :tippy-options="{ duration: 100, placement: 'top-start' }"
                :editor="editor"
                style="flex-direction: column"
                v-if="!disabled"
                :should-show="shouldShow"
                >
                <div style="flex: 1; display: flex;">
                    <Dropdown style="" class="dropdown-120 labelEditorFontFamilySelect" v-model="selectedTextFontFamily" :options="fontFamilyList" optionLabel="label" optionValue="value" @change="changeFontFamily" v-tooltip.top="$t('tiptapEditor.fontFamily.iconText')" > </Dropdown>
                    <span class="material-symbols-outlined cursor_pointer" style="position: relative;" @click="onFontSizeIconClick" v-tooltip.top="$t('tiptapEditor.formatSize.iconText')"> format_size
                        <Dropdown ref="labelEditorFontSizeSelect" class="labelEditorFontSizeSelect" v-model="selectedTextFontSize" :options="fontSizeList" optionLabel="label" optionValue="value" @change="changeFontSize" > </Dropdown>
                    </span>
                    <span class="material-symbols-outlined cursor_pointer" @click="onFontSizeDecIconClick" v-tooltip.top="$t('tiptapEditor.formatSizeDec.iconText')"> text_decrease </span>
                    <span class="material-symbols-outlined cursor_pointer" @click="onFontSizeIncIconClick" v-tooltip.top="$t('tiptapEditor.formatSizeInc.iconText')"> text_increase </span>

                    <span class="material-symbols-outlined cursor_pointer" v-if="!disabledFunctionList.includes('bulletList')" @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }" v-tooltip.top="$t('tiptapEditor.formatListBulleted.iconText')"> format_list_bulleted </span>
                    <span class="material-symbols-outlined cursor_pointer" @click="setLink" :class="{ 'is-active': editor.isActive('link') }" v-tooltip.top="$t('tiptapEditor.setLink.iconText')"> link </span>
                    <span class="material-symbols-outlined cursor_pointer" @click="editor.chain().focus().unsetLink().run()" :disabled="!editor.isActive('link')" v-tooltip.top="$t('tiptapEditor.unsetLink.iconText')"> link_off </span>
                </div>
                <div style="flex: 1;">
                    <span class="material-symbols-outlined cursor_pointer" @click="onCopyTextIconClick" :class="{ 'is-active': editor.isActive('library_books') }" v-tooltip.bottom="$t('tiptapEditor.copy.iconText')"> library_books </span>
                    <span class="material-symbols-outlined cursor_pointer" @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }" v-tooltip.bottom="$t('tiptapEditor.formatBold.iconText')"> format_bold </span>
                    <span class="material-symbols-outlined cursor_pointer" @click="toggleUnderlineStrike" :class="{ 'is-active': editor.isActive('strike') }" v-tooltip.bottom="$t('tiptapEditor.strike.iconText')"> strikethrough_s </span>
                    <span class="material-symbols-outlined cursor_pointer" @click="toggleUnderline" :class="{ 'is-active': editor.isActive('underline') }" v-tooltip.bottom="$t('tiptapEditor.formatUnderline.iconText')" > format_underlined </span>
                    <span class="material-symbols-outlined cursor_pointer" @click="toggleUnderlineSquiggle" :class="{ 'is-active': editor.isActive('underlineSquiggle') }" v-tooltip.bottom="$t('tiptapEditor.formatUnderlineSquiggle.iconText')" > format_underlined_squiggle </span>
                    <span class="material-symbols-outlined cursor_pointer" style="position: relative;text-shadow: black 0px 0px;" v-bind:style="{ 'color': selectedHighlight }" @click="onHighlightIconClick" :disabled="!editor.isActive('backgroundColor')" v-tooltip.bottom="$t('tiptapEditor.formatInkHighlight.iconText')" > format_ink_highlighter
                        <Dropdown ref="hightLightColorDropdown" class="labelEditorHighlightSelect" :options="colorList" optionValue="labelValue" @change="changeHighlight"  >
                        <template #value="slotProps">
                            <div class="" v-if="selectedHighlight" style="height: 100%; width: 100%; position: absolute; top: 0px; left: 0px;" v-bind:style="{ 'background-color': selectedHighlight }">
                            </div>
                        </template>
                        <template #option="slotProps">
                            <div class="" style=" height: 100%; width: 100%; position: absolute; top: 0px; left: 0px;" v-bind:style="{ 'background-color': slotProps.option.labelValue }">
                            </div>
                        </template>
                        </Dropdown>
                    </span>
                    <span class="material-symbols-outlined cursor_pointer" style="position: relative;text-shadow: black 0px 0px;" v-bind:style="{ 'color': selectedTextFontColor }" @click="onFontColorIconClick" :disabled="!editor.isActive('color')" v-tooltip.bottom="$t('tiptapEditor.fontColor.iconText')"> format_color_text 
                        <Dropdown ref="fontColorDropdown" class="labelEditorFontColorSelect" :options="fontColorList" optionValue="value" @change="changeFontColor"  >
                        <template #value="slotProps">
                            <div class="" v-if="selectedTextFontColor" style="height: 100%; width: 100%; position: absolute; top: 0px; left: 0px;" v-bind:style="{ 'background-color': selectedTextFontColor }">
                            </div>
                        </template>
                        <template #option="slotProps">
                            <div class="" style=" height: 100%; width: 100%; position: absolute; top: 0px; left: 0px;" v-bind:style="{ 'background-color': slotProps.option.value }">
                            </div>
                        </template>
                        </Dropdown>
                    </span>
                    <span class="material-symbols-outlined cursor_pointer" @click="onFormatAlignClick('left')" v-tooltip.bottom="$t('tiptapEditor.fontAlignLeft.iconText')"> format_align_left </span>
                    <span class="material-symbols-outlined cursor_pointer" @click="onFormatAlignClick('center')" v-tooltip.bottom="$t('tiptapEditor.fontAlignCenter.iconText')"> format_align_center </span>
                    <span class="material-symbols-outlined cursor_pointer" @click="onFormatAlignClick('right')" v-tooltip.bottom="$t('tiptapEditor.fontAlignRight.iconText')"> format_align_right </span>
                    <input type="file" style="display: none;" ref="uploadButton" @change="changeUploadFile" />
                </div>
        </bubble-menu>
        <floating-menu :editor="editor" :tippy-options="{ duration: 100 }" v-if="showFloatingMenu">
            <div style="width: 14px; height: 10px; display: inline-block;" />
            <span class="material-symbols-outlined cursor_pointer" @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }"> format_list_bulleted </span>
            <span class="material-symbols-outlined cursor_pointer" v-if="!disabledFunctionList.includes('image')" @click="onImageIconClick" > image </span>
            <input type="file" style="display: none;" ref="uploadButton" @change="changeUploadFile" />
        </floating-menu>


    <editor-content ref="editor" :editor="editor" class="" :class="[className, {'label-editor-disabled': disabled, 'viewerFullEditorsEditor': disabled && disableEnterKey}]" @dragover.prevent="onDrag('over')"  @drop.prevent="onDrop($event)" 

      @keydown.ctrl.v.exact="onPaste"
      @paste="onPaste"
        @click="onEditorContentClick" />
        <i v-if="showEditUserIcon" style="position: absolute; bottom: 10px; left: 10px; " v-tooltip="$t('xtype.editor4Applicant.iconText')">
            <EditUserIcon  />
        </i>
        <i v-if="showEditIcon" style="position: absolute; bottom: 10px; left: 10px; " v-tooltip="$t('xtype.editor.iconText')">
            <EditIcon />
        </i >
    <EditorImagePopup :imgs="src" :index="imageIndex" :visibleProps="isShowPreview" @hide="isShowPreview=false" />
    </div>
</template>

<script>
import EditIcon from "@/components/Icons/editIcon";
import EditUserIcon from "@/components/Icons/userEditIcon";
import EditorImagePopup from "@/components/EditorImagePopup";

import StarterKit from '@tiptap/starter-kit'
import Link from '@/components/tiptap-extensions/link'
import Underline from '@/components/tiptap-extensions/underline'
import UnderlineSquiggle from '@/components/tiptap-extensions/underline-squiggle'
import UnderlineStrike from '@/components/tiptap-extensions/strike'
import BackgroundColorExtension from '@/components/tiptap-extensions/background-color'
import FontFamily from '@tiptap/extension-font-family'
import { Color } from '@tiptap/extension-color'
import TextStyle from '@tiptap/extension-text-style'
import FontSize from '@/components/tiptap-extensions/font-size'
import TextAlign from '@tiptap/extension-text-align'
import Heading from '@/components/tiptap-extensions/heading'

import Document from '@tiptap/extension-document'
import Dropcursor from '@tiptap/extension-dropcursor'
import Image from '@tiptap/extension-image'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import { Editor, EditorContent, BubbleMenu, FloatingMenu } from '@tiptap/vue-3'
import Tooltip from 'primevue/tooltip'
import Dropdown from 'primevue/dropdown';
import Placeholder from '@tiptap/extension-placeholder'
import PreventEnter from '@/components/tiptap-extensions/prevent-enter';


import helper from '@/mixins/helper';

export default {
    name: 'tiptapExtensionEditor',
    props: {
        content: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: "",
        },
        disabledFunctionList: {
            type: Array,
            default: [],
        },
        disableEnterKey: {
            type: Boolean,
            default: false,
        },
        showFloatingMenu: {
            type: Boolean,
            default: false,
        },
        showEditIcon: {
            type: Boolean,
            default: false,
        },
        showEditUserIcon: {
            type: Boolean,
            default: false,
        },
        useMinHeight: {
            type: Boolean,
            default: true,
        }
    },
    emits: ['onUpdate', 'onPaste', 'onDrop'],
    components: {
        EditorContent,
        EditIcon,
        EditUserIcon,
        EditorImagePopup,
        Dropdown,
        BubbleMenu,
        FloatingMenu,
    },
    data() {
        return {
            editor: null,
            isShowPreview: false,
            src: "",
            fontFamilyList: this.$constants.tiptapEditorsFontFamilyList,
            fontSizeList: this.$constants.tiptapEditorsFontSizeList,
            colorList: this.$constants.tiptapEditorsColorList,
            fontColorList: this.$constants.tiptapEditorsFontColorList,
        }
    },
    watch: {
    },
    mounted() {
        const self = this

        const extensions = []

        const defaultExtensions = [
                Document,
                Paragraph,
                Text,
                Image,
                Dropcursor,
                StarterKit.configure({
                    strike: false,
                }),
                Link.configure({
                    openOnClick: false,
                }),
                Underline,
                UnderlineSquiggle,
                UnderlineStrike,
                BackgroundColorExtension,
                TextStyle,
                FontFamily,
                Color,
                FontSize,
                TextAlign.configure({
                    types: ['heading', 'paragraph'],
                }),
                Heading.configure({
                }),
                Placeholder.configure({
                    placeholder: self.placeholder,
                    showOnlyWhenEditable: false,
                    showOnlyCurrent: false,
                }),
            ]
        extensions.push(...defaultExtensions)
        if (this.disableEnterKey) {
            extensions.push(PreventEnter)
        }


        this.editor = new Editor({
            extensions: extensions,
            // enablePasteRules: false,
            content: this.content,
            editable: !this.disabled,
            onUpdate({ editor, event }) {
                if (!self.disabled) {
                    console.log({html: editor.getHTML(), text: editor.getText()})
                    self.input({html: editor.getHTML(), text: editor.getText()})
                }
            },
        })
    },

    beforeUnmount() {
        this.editor.destroy()
    },

    computed: {
        editorContainerClass() {
            return {
                [this.editorContainerClass]: this.editorContainerClass,
            }
        },
        className() {
            return {
                [this.className]: this.className,
                "tiptapExtensionEditorContainer": true
            }
        },
        selectedTextFontFamily: {
            get() {
                for (let i = 0; i < this.fontFamilyList.length; i++) {
                    const isActiveFont = this.editor.isActive('textStyle', { fontFamily: this.fontFamilyList[i].value })
                    if (isActiveFont) {
                        return this.fontFamilyList[i].value
                    }
                }
                return this.fontFamilyList[0].value // default
            },
            set(value) {
            },
        },
        selectedTextFontSize: {
            get() {
                let defaultValue
                for (let i = 0; i < this.fontSizeList.length; i++) {
                    if (this.fontSizeList[i].isDefault) {
                        defaultValue = this.fontSizeList[i].value
                    }
                }
                
                const textStyle = this.editor.getAttributes('textStyle')
                const fontSizeText = textStyle.fontSize ? textStyle.fontSize : defaultValue + "px"
                const fontSize = Number(fontSizeText.replace("px", ""));
                for (let i = 0; i < this.fontSizeList.length; i++) {
                    if (this.fontSizeList[i].value == fontSize) {
                        return this.fontSizeList[i].value
                    }
                }
            },
            set(value) {
            },
        },
        selectedHighlight: {
            get() {
                for (let i = 0; i < this.colorList.length; i++) {
                    const isActiveColor = this.editor.isActive('textStyle', { backgroundColor: this.colorList[i].value })
                    if (isActiveColor) {
                        return this.colorList[i].value
                    }
                }
                return this.colorList[0].value
            },
            set(value) {
            },
        },
        selectedTextFontColor: {
            get() {
                for (let i = 0; i < this.fontColorList.length; i++) {
                    const isActiveFont = this.editor.isActive('textStyle', { color: this.fontColorList[i].value })
                    if (isActiveFont) {
                        return this.fontColorList[i].value
                    }
                }
                return this.fontColorList[0].value
            },
            set(value) {
            },
        },
    },
    methods: {
        input: function(e={}) {
            const data = {
                html: e.html,
                text: e.text,
            }

            this.$emit("onUpdate", data)
        },

        async onDrop(e) {
            this.$emit('onDrop', e)
        },

        onEditorContentClick(e, t) {
            const target = e.target
            if (target.localName == "img") {
                this.src = target.currentSrc
                this.isShowPreview = true
                this.imageIndex = 0
            }
        },

        setLink() {
            // const previousUrl = this.editor.getAttributes('link').href
            const previousUrl = this.editor.getAttributes('link').originalHref
            const url = window.prompt('URL', previousUrl)

            // cancelled
            if (url === null) {
                return
            }

            // empty
            if (url === '') {
                this.editor
                    .chain()
                    .focus()
                    .extendMarkRange('link')
                    .unsetLink()
                    .run()

                return
            }
            this.onSetLink(url)
        },

        onSetLink(url) {
            const self = this
            this.editor
                .chain()
                .focus()
                .run()

            setTimeout(() => {
                const link = self.$constants.local + "/inputforms_redirect?redirectUrl=" + encodeURIComponent(url) + "&redirectUrlText=" + encodeURIComponent(window.getSelection().toString())
                self.editor
                    .chain()
                    .focus()
                    .extendMarkRange('link')
                    .setLink({ href: link, originalHref: url })
                    .run()

            }, 100)
        },
        changeFontFamily(e) {
            const fontFamily = e.value
            if(fontFamily && fontFamily != "Defalt") {
                this.editor.chain().focus().setFontFamily(fontFamily).run()
            } else {
                this.editor.chain().focus().unsetFontFamily().run()
                this.selectedTextFontFamily = "Defalt"
            }
        },
        changeHighlight(e) {
            console.log(e)
            const color = this.colorList.find(x => x.labelValue == e.value)
            const highLight = color.value
            if(highLight) {
                this.editor.chain().focus().setBackgroundColor(highLight).run()
            } else {
                this.editor.chain().focus().unsetBackgroundColor().run()
            }
        },
        changeFontSize(e) {
            const fontSize = e.value
            if(fontSize) {
                this.editor.chain().focus().setFontSize(fontSize + "px").run()
            } else {
                this.editor.chain().focus().unsetFontSize().run()
                // this.selectedTextFontSize = ""
            }
        },
        changeFontColor(e) {
            const color = e.value
            if(color) {
                this.editor.chain().focus().setColor(color).run()
            } else {
                this.editor.chain().focus().unsetColor().run()
            }
        },
        onHighlightIconClick(e) {
            this.$refs.hightLightColorDropdown.show()
            this.editor.chain().focus().run()
        },
        onFontColorIconClick(e) {
            this.$refs.fontColorDropdown.show()
            this.editor.chain().focus().run()
        },

        onFontSizeIconClick() {
            this.$refs.labelEditorFontSizeSelect.show()
            this.editor.chain().focus().run()

        },

        toggleUnderline() {
            const textStyle = this.editor.getAttributes('textStyle')
            if(textStyle.underline) {
                this.editor.chain().focus().unsetUnderline('underline').run()
            } else {
                this.editor.chain().focus().setUnderline('underline').run()
            }
        },

        toggleUnderlineSquiggle() {
            const textStyle = this.editor.getAttributes('textStyle')
            if(textStyle.underlineWavy) {
                this.editor.chain().focus().unsetUnderlineSquiggle('underline wavy').run()
            } else {
                this.editor.chain().focus().setUnderlineSquiggle('underline wavy').run()
            }
        },
        toggleUnderlineStrike() {
            const textStyle = this.editor.getAttributes('textStyle')
            if(textStyle.underlineStrike) {
                this.editor.chain().focus().unsetUnderlineStrike('line-through').run()
            } else {
                this.editor.chain().focus().setUnderlineStrike('line-through').run()
            }
        },
        onImageIconClick() {
            this.$refs.uploadButton.click();
        },
        onFormatAlignClick(align) {
            this.editor.chain().focus().setTextAlign(align).run()
        },
        changeUploadFile(e) {
            this.uploadImages(e.target.files)
        },
        shouldShow({ editor, view, state, oldState, from, to }) {
            if (from == to) return false;
            if (state.selection.node && state.selection.node.type.name == "image") return false; // nodeは普通はないらしい
            return true;
        },
        getNextFontSize(is_large, value) {
            const fontSizeList = this.fontSizeList
            for (let i = 0; i < fontSizeList.length; i++) {
                if(value == fontSizeList[i].value) {
                    if (is_large) {
                        if (i == 0) return value
                        return fontSizeList[i-1].value
                    } else {
                        if (i == fontSizeList.length - 1) return value
                        return fontSizeList[i+1].value
                    }
                }
            }
        },

        onFontSizeIncIconClick() {
            const textStyle = this.editor.getAttributes('textStyle')
            const fontSizeText = textStyle.fontSize ? textStyle.fontSize : "14px"
            const fontSize = Number(fontSizeText.replace("px", ""));
            this.changeFontSize({value: this.getNextFontSize(true, fontSize)})
        },
        onFontSizeDecIconClick() {
            const textStyle = this.editor.getAttributes('textStyle')
            const fontSizeText = textStyle.fontSize ? textStyle.fontSize : "14px"
            const fontSize = Number(fontSizeText.replace("px", ""));
            this.changeFontSize({value: this.getNextFontSize(false, fontSize)})
        },
        async onPaste(event) {
            const data = helper.getDataListFromClipboard(event)
            if (data.DataType == "text") {

            }
            this.$emit('onPaste', event)
        },
        onCopyTextIconClick() {
            const { from, to } = this.editor.state.selection
            const text = this.editor.state.doc.textBetween(from, to, ' ')
            helper.copyTextToClipboard(text ? text : " ")
            this.$toast.add({severity: 'success', summary: this.$i18n.tc("utils.copied"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
        },

    },
    directives: {
        'tooltip': Tooltip
    },
}
</script>

<style scoped>
</style>

