import axiosBase from 'axios'
import constants from './constants'
import http from './api'
export default { 
    name: 'download',
    async fileDownload (_id, fileName) {
        const res = await http.post('/form/GetAttachmentOnForm', { "_id": _id }, { responseType: 'blob' })
        var alink = document.createElement('a');
        alink.download = fileName;
        alink.href = window.URL.createObjectURL(new Blob([res]));
        if (window.navigator.msSaveBlob) {
            var xhr = new XMLHttpRequest();
            xhr.open("GET", alink.href, true);
            xhr.responseType = "blob";
            xhr.onload = function (e) {
                var blob = xhr.response;
                window.navigator.msSaveBlob(blob, fileName);
            }
            xhr.send();
        }else{
            const e = new MouseEvent('click', { view: window, bubbles: true, cancelable: true });
            alink.dispatchEvent(e);
        }
    },
    async download(fileName, url, createObjectURL=true) {
        var alink = document.createElement('a');
        alink.download = fileName;
        console.log(fileName)
        console.log(url)
        alink.href = createObjectURL ? URL.createObjectURL(url) : url;
        if (window.navigator.msSaveBlob) {
            var xhr = new XMLHttpRequest();
            xhr.open("GET", alink.href, true);
            xhr.responseType = "blob";
            xhr.onload = function (e) {
                var blob = xhr.response;
                window.navigator.msSaveBlob(blob, fileName);
            }
            xhr.send();
        }else{
            const e = new MouseEvent('click', { view: window, bubbles: true, cancelable: true });
            alink.dispatchEvent(e);
        }
        alink.remove();
        URL.revokeObjectURL(url);
    }
}
