import constants from './constants'
import helper from "./helper"
import http from "./api"

export default {
    name: 'fileUploadHelper',

    imageUploadInTemplate: async function(templateId) {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.click();
        const self = this
        let result = new Promise(function(res, rej) {
            input.onchange = async () => {
                const file = input.files[0];
                if (/^image\//.test(file.type)) {
                    res(await self.saveToServer(file, templateId));
                } else {
                    console.warn('You could only upload images.');
                    res({Success: false});
                }
            };
        })


        return result
    },

    image64UploadInTemplate: async function(imageDataUrl, imageData, templateId) {
        const parseBlob = this.blobCreationFromURL(imageDataUrl)
        const parseFile = new File([parseBlob], imageData.name)
        return await this.saveToServer(parseFile, templateId);
    },

    saveToServer: async function(file, templateId) {
        const fd = new FormData();
        fd.append('files', file);
        fd.append('templateId', templateId);
        let res = {}
        if (templateId) {
            res = await http.post("/form/TemplateInputtingFormImageUpload", fd, {'content-type': 'multipart/form-data'})
        }
        else {
            res = await http.post("/form/InputtingFormImageUpload", fd, {'content-type': 'multipart/form-data'}, false, false)
        }
        return res;
    },

    blobCreationFromURL: function(dataURL) {
        var BASE64_MARKER = ';base64,';
        if (dataURL.indexOf(BASE64_MARKER) == -1) {
            var parts = dataURL.split(',');
            var contentType = parts[0].split(':')[1];
            var raw = decodeURIComponent(parts[1]);
            return new Blob([raw], {type: contentType});
        }
        var parts = dataURL.split(BASE64_MARKER);
        var contentType = parts[0].split(':')[1];
        var raw = window.atob(parts[1]);
        var rawLength = raw.length;
        var uInt8Array = new Uint8Array(rawLength);
        for (var i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }
        return new Blob([uInt8Array], {type: contentType});
    },

}

