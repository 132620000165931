import '@tiptap/extension-text-style'

import { Extension } from '@tiptap/core'

const BackgroundColorExtension = Extension.create({
    name: 'backgroundColor',

    // Your code goes here.
    addOptions() {
        return {
            types: ['textStyle'],
        }
    },

    addGlobalAttributes() {
        return [
            {
                types: this.options.types,
                attributes: {
                    backgroundColor: {
                        default: null,
                        parseHTML: element => element.style.backgroundColor?.replace(/['"]+/g, ''),
                            renderHTML: attributes => {
                                if (!attributes.backgroundColor) {
                                    return {}
                                }

                                return {
                                    style: `background-color: ${attributes.backgroundColor}`,
                                }
                            },
                    },
                },
            },
                        ]
    },

    addCommands() {
        return {
            setBackgroundColor: backgroundColor => ({ chain }) => {
                return chain()
                    .setMark('textStyle', { backgroundColor })
                    .run()
            },
            unsetBackgroundColor: () => ({ chain }) => {
                return chain()
                    .setMark('textStyle', { backgroundColor: null })
                    .removeEmptyTextStyle()
                    .run()
            },
        }
    },
})

export default BackgroundColorExtension
