<template>
    <fa icon="user-edit" />
</template>

<script>


export default {
    name: 'userEditIcon',
    props: ['tooltipText'],
    emits: [],
    components: {
    },
    data: function() {
        return {
        }
    },
    created() {
    },
    computed: {
    },
    watch: {
    },
    methods: {
    },
}

</script>

<style scoped>
</style>
