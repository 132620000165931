import '@tiptap/extension-text-style'

import { Extension } from '@tiptap/core'

const UnderlineSquiggle = Extension.create({
    name: 'underlineSquiggle',

    addOptions() {
        return {
            types: ['textStyle'],
        }
    },

    addGlobalAttributes() {
        return [
            {
                types: this.options.types,
                attributes: {
                    underlineWavy: {
                        default: null,
                        // parseHTML: element => element.style.underlineWavy?.replace(/['"]+/g, ''),
                        parseHTML: element => {
                            if (element.style["text-decoration"] == "underline wavy") return element.style["text-decoration"]
                            return null
                            // return element.style.underlineWavy?.replace(/['"]+/g, '')
                        },
                        renderHTML: attributes => {
                            if (!attributes.underlineWavy) {
                                return {}
                                // return attributes
                            }

                            return {
                                style: `text-decoration: ${attributes.underlineWavy}`,
                            }
                        },
                    },
                },
            },
        ]
    },

    addCommands() {
        return {
            setUnderlineSquiggle: underlineWavy => ({ chain }) => {
                chain()
                    .unsetUnderlineStrike()
                    .run()
                chain()
                    .unsetUnderline()
                    .run()
                return chain()
                    .setMark('textStyle', { underlineWavy})
                    .run()
            },
            toggleUnderlineSquiggle: underlineWavy => ({ chain }) => {
                return chain()
                    .toggleMark('textStyle', { underlineWavy })
                    // .toggleMark('textStyle', { "text-decoration": "underline wavy" })
                    // .toggleMark('underlineSquiggle')
                    .run()
            },
            unsetUnderlineSquiggle: () => ({ chain }) => {
                return chain()
                    .setMark('textStyle', { underlineWavy: null })
                    .removeEmptyTextStyle()
                    .run()
            },
        }
    },
})

export default UnderlineSquiggle

//
//
// //
// // import { Mark, mergeAttributes } from '@tiptap/core'
// //
// // export const UnderlineSquiggle = Mark.create({
// //   name: 'underlineSquiggle',
// //
// //   addOptions() {
// //     return {
// //         HTMLAttributes: {'style': 'text-decoration: underline wavy'},
// //     }
// //   },
// //
// //   parseHTML() {
// //     return [
// //       {
// //         tag: 'u',
// //         getAttrs: element => {
// //           if (element.style["text-decoration"] == "underline wavy") {
// //             return true
// //           }
// //           return false
// //         }
// //       },
// //     ]
// //   },
// //
// //   renderHTML({ HTMLAttributes }) {
// //     return ['u', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
// //   },
// //
// //   addCommands() {
// //     return {
// //       setUnderlineSquiggle: () => ({ commands }) => {
// //         return commands.setMark(this.name)
// //       },
// //       toggleUnderlineSquiggle: () => ({ commands }) => {
// //         return commands.toggleMark(this.name)
// //       },
// //       unsetUnderlineSquiggle: () => ({ commands }) => {
// //         return commands.unsetMark(this.name)
// //       },
// //     }
// //   },
// //
// //   addKeyboardShortcuts() {
// //     return {
// //       'Mod-u': () => this.editor.commands.toggleUnderlineSquiggle(),
// //       'Mod-U': () => this.editor.commands.toggleUnderlineSquiggle(),
// //     }
// //   },
// // })
// //
// // export default UnderlineSquiggle
