<template>
    <div :class="class" style="">
        <tiptapExtensionEditor :useMinHeight="false" :className="'labelEditor'" :content="content" :disabled="disabled" :placeholder="placeholder" :disabledFunctionList="disabledFunctionList" :disableEnterKey="disableEnterKey" @onUpdate="onUpdate" />
    </div>
</template>

<script>
import tiptapExtensionEditor from "@/components/tiptap-extensions";


export default {
    props: {
        class: {
            type: String,
            default: "",
        },
        content: {
            type: String,
        },
        disabled: {
            type: Boolean,
        },
        placeholder: {
            type: String,
            default: "",
        },
        disabledFunctionList: {
            type: Array,
            default: ['image']
        },
        disableEnterKey: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['onBlurContent'],
    components: {
        tiptapExtensionEditor,
    },

    data() {
        return {
        }
    },

    computed: {
    },

    mounted() {
    },

    methods: {
        onUpdate(e) {
            console.log(e)
            this.$emit('onBlurContent', e)
        }
    },


    beforeUnmount() {
    },
}
</script>

<style lang="scss">
</style>
