import Heading from '@tiptap/extension-heading'


const CustomHeading = Heading.extend({
  addOptions() {
    return {
        levels: [1, 2, 3, 4, 5, 6],
        HTMLAttributes: {'style': 'font-weight: normal'},
    }
  },
  addCommands() {
    return {
      clearHeading: attributes => ({ commands }) => {
        // if (!this.options.levels.includes(attributes.level)) {
        //   return false
        // }

        return commands.clearNode(this.name, attributes)
      },
    }
  },
})

export default CustomHeading
