import '@tiptap/extension-text-style'

import { Extension } from '@tiptap/core'

const FontSizeExtension = Extension.create({
    name: 'fontSize',

    // Your code goes here.
    addOptions() {
        return {
            types: ['textStyle'],
        }
    },

    addGlobalAttributes() {
        return [
            {
                types: this.options.types,
                attributes: {
                    fontSize: {
                        default: null,
                        parseHTML: element => element.style.fontSize?.replace(/['"]+/g, ''),
                            renderHTML: attributes => {
                                if (!attributes.fontSize) {
                                    return {}
                                }

                                return {
                                    style: `font-size: ${attributes.fontSize}`,
                                }
                            },
                    },
                },
            },
                        ]
    },

    addCommands() {
        return {
            setFontSize: fontSize => ({ chain }) => {
                return chain()
                    .setMark('textStyle', { fontSize })
                    .run()
            },
            unsetFontSize: () => ({ chain }) => {
                return chain()
                    .setMark('textStyle', { fontSize: null })
                    .removeEmptyTextStyle()
                    .run()
            },
        }
    },
})

export default FontSizeExtension
