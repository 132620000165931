import '@tiptap/extension-text-style'

import { Extension } from '@tiptap/core'

const UnderlineStrike = Extension.create({
    name: 'underlineStrike',

    addOptions() {
        return {
            types: ['textStyle'],
        }
    },

    addGlobalAttributes() {
        return [
            {
                types: this.options.types,
                attributes: {
                    underlineStrike: {
                        default: null,
                        // parseHTML: element => element.style.underlineWavy?.replace(/['"]+/g, ''),
                        parseHTML: element => {
                            if (element.style["text-decoration"] == "line-through") return element.style["text-decoration"]
                            return null
                        },
                        renderHTML: attributes => {
                            if (!attributes.underlineStrike) {
                                return {}
                                // return attributes
                            }

                            return {
                                style: `text-decoration: ${attributes.underlineStrike}`,
                            }
                        },
                    },
                },
            },
        ]
    },

    addCommands() {
        return {
            setUnderlineStrike: underlineStrike => ({ chain }) => {
                chain()
                    .setUnderlineSquiggle()
                    .run()
                chain()
                    .unsetUnderline()
                    .run()
                return chain()
                    .setMark('textStyle', { underlineStrike})
                    .run()
            },
            toggleUnderlineStrike: underlineStrike => ({ chain }) => {
                return chain()
                    .toggleMark('textStyle', { underlineStrike })
                    .run()
            },
            unsetUnderlineStrike: () => ({ chain }) => {
                return chain()
                    .setMark('textStyle', { underlineStrike: null })
                    .removeEmptyTextStyle()
                    .run()
            },
        }
    },
})

export default UnderlineStrike
