// import Underline from '@tiptap/extension-underline'
//
//
// const CustomUnderline = Underline.extend({
//   addOptions() {
//     return {
//         HTMLAttributes: {'style': 'text-decoration: underline'},
//     }
//   },
//    parseHTML() {
//      return [
//        {
//          tag: 'u',
//          getAttrs: element => {
//            if (element.style["text-decoration"] == "underline") {
//              return true
//            }
//            return false
//          }
//        },
//      ]
//    },
// })
//
// export default CustomUnderline
//


import '@tiptap/extension-text-style'

import { Extension } from '@tiptap/core'

const CustomUnderline = Extension.create({
    name: 'customUnderline',

    addOptions() {
        return {
            types: ['textStyle'],
        }
    },

    addGlobalAttributes() {
        return [
            {
                types: this.options.types,
                attributes: {
                    underline: {
                        default: null,
                        // parseHTML: element => element.style.underline?.replace(/['"]+/g, ''),
                        parseHTML: element => {
                            if (element.style["text-decoration"] == "underline") return element.style["text-decoration"]
                            return null
                            // console.log(element)
                            // console.log(element.style)
                            // console.log(element.style["text-decoration-line"])
                            // return element.style["text-decoration-line"]
                            // return element.style.underline?.replace(/['"]+/g, '')
                        },
                        renderHTML: attributes => {
                            // if (attributes.style["text-decoration"] == "underline") {
                            //     return {
                            //         style: `text-decoration: ${attributes.underline}`,
                            //     }
                            // }
                            // return {}
                            if (!attributes.underline) {
                                return {}
                                // return attributes
                            }

                            return {
                                style: `text-decoration: ${attributes.underline}`,
                            }
                        },
                    },
                },
            },
        ]
    },

    addCommands() {
        return {
            setUnderline: underline => ({ chain }) => {
                chain()
                    .unsetUnderlineStrike()
                    .run()
                chain()
                    .unsetUnderlineSquiggle()
                    .run()
                return chain()
                    .setMark('textStyle', { underline})
                    .run()
            },
            toggleUnderline: underline => ({ chain }) => {
                return chain()
                    .toggleMark('textStyle', { underline })
                    .run()
            },
            unsetUnderline: () => ({ chain }) => {
                return chain()
                    .setMark('textStyle', { underline: null })
                    .removeEmptyTextStyle()
                    .run()
            },
        }
    },
})

export default CustomUnderline
